<template>
  <div class="footer">

    <div class="foot-container">
      <div class="foot-container-images">
        <div class="foot-container-images-left">
          <div class="header-sign logo">
            <img src="https://zhengyoulinart.obs.cn-east-3.myhuaweicloud.com/2023/01/04/f01e97ef524447e58ca38ecf9fa37483.png" />
          </div>
          <div class="sign">
            <img src="https://zhengyoulinart.obs.cn-east-3.myhuaweicloud.com/2023/01/04/6dee1ecd07604d049eb657e0b008dc89.png" />
          </div>
        </div>
        <div class="foot-container-images-right">
          <div class="foot-container-images-right-item">
            <div class="header-sign2 ewm">
              <img src="https://zhengyoulinart.obs.cn-east-3.myhuaweicloud.com/2023/01/04/35fc5c8e0122470d8a06ba812ce7532f.jpg" />
            </div>
            <div class="des">
              <div>关注服务号</div>
              <div>微信扫码关注</div>
              <div>郑幼林雕刻艺术馆</div>
            </div>
          </div>
          <div class="foot-container-images-right-item">
            <div class="ewm">
              <img src="https://zhengyoulinart.obs.cn-east-3.myhuaweicloud.com/2023/01/04/35fc5c8e0122470d8a06ba812ce7532f.jpg" />
            </div>
            <div class="des">
              <div>关注服务号</div>
              <div>微信扫码关注</div>
              <div>郑幼林雕刻艺术馆</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="foot-menus" v-if="menus && menus.length > 0">
      <div class="foot-menus-item" :style="{ 'width': (1000 / menus.length) + 'px' }" v-for="(i, j) in menus"
        :key="i.id">
        <div class="first-title">
          <span>{{ i.title }}</span>
        </div>
        <div class="childrens" v-if="i.children && i.children.length > 0">
          <div class="childrens-item" v-for="(m, n) in i.children" :key="m.id">
            <a :href="m.openUrl" target="_blank" v-if="m.openStyle == '1'">
              {{ m.title }}
            </a>
            <span v-else @click="footMenuClickFn(m)">{{ m.title }}</span>
          </div>
        </div>
      </div>
    </div>

    <div class="footer-icp">
      <a href="https://beian.miit.gov.cn" target="_blank" title="点击前往查询备案信息">闽ICP备2021008383号</a>
    </div>
  </div>
</template>

<script>
import { get } from "../../utils/request";
import { Message } from 'element-ui'
export default {
  data() {
    return {
      menus: []
    };
  },

  created() {
    this.getBottomMenusFn();
  },

  methods: {
    async getBottomMenusFn() {
      const { data: res } = await get("/public/bottomMenus");
      if (res.code == 200)
        this.menus = res.data;
    },
    footMenuClickFn(menu) {
      if (!menu.openUrl) return Message({ message: '敬请期待，功能开发中', type: 'success', duration: 5 * 1000 });
      this.$router.push({
        path: menu.openUrl
      });
    }
  },
};
</script>
<style lang='less' scoped>
.footer {

  width: 100%;
  margin: 0 auto;

  .foot-container {
    max-width: 1000px;
    margin: 0 auto;

    .foot-container-images {
      height: 100px;
      display: flex;
      justify-content: space-between;
      margin: 30px 0;

      .foot-container-images-left {
        .logo {
          margin-bottom: 10px;

          img {
            height: 50px;
          }
        }

        .sign {
          img {
            height: 40px;
          }
        }
      }

      .foot-container-images-right {
        display: flex;
        justify-content: flex-end;

        .foot-container-images-right-item {
          display: flex;
          align-items: center;
          margin-right: 20px;

          .ewm {
            img {
              height: 80px;
            }
          }

          .des {
            margin-left: 10px;
            padding-top: 16px;
          }
        }
      }
    }

  }

  .foot-menus {
    max-width: 1000px;
    margin: 0 auto;
    display: flex;
    justify-content: space-around;

    .first-title {
      font-weight: 700;
      font-size: 14px;
      color: #0b0b0b;
      padding: 10px 0;
    }

    .childrens {
      .childrens-item {
        font-size: 14px;
        color: #ababab;
        padding: 10px 0;
      }

      .childrens-item:hover {
        cursor: pointer;
      }
    }

  }

  .footer-icp {
    height: 30px;
    line-height: 30px;
    text-align: center;
    background-color: #edecec;
  }

}
</style>