import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
        path: "/",
        redirect: "/index"
    },
    {
        path: '/index',
        name: 'index',
        meta: {
            scrollToTop: true
        },
        component: () =>
            import ('../views/Index.vue')
    },
    {
        path: '/articlesList',
        name: 'articlesList',
        meta: {
            scrollToTop: true
        },
        component: () =>
            import ('../views/Articles/articlesList.vue')
    },
    {
        path: '/articlesContent',
        name: 'articlesContent',
        meta: {
            scrollToTop: true
        },
        component: () =>
            import ('../views/Articles/articlesContent.vue')
    },
]

//路由导航冗余报错（路由重复）
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    // mode: 'hash',
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router