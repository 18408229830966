<template>
  <div class="header">
    <div class="header-sign header-left">
      <img src="https://zhengyoulinart.obs.cn-east-3.myhuaweicloud.com/2023/01/04/f01e97ef524447e58ca38ecf9fa37483.png" />
    </div>
    <div class="header-right">
      <div class="header-right-menus">
        <div class="header-sign2 header-right-menus-sign" v-if="headerMenus && headerMenus.length > 0">
          <img src="https://zhengyoulinart.obs.cn-east-3.myhuaweicloud.com/2023/01/04/6dee1ecd07604d049eb657e0b008dc89.png" />
        </div>
        <div class="menus" v-if="headerMenus && headerMenus.length > 0">
          <div class="menus-item" v-for="(i, j) in headerMenus" :key="i.id">
            <a :href="i.openUrl" target="_blank" v-if="i.openStyle == '1'">
              {{ i.title }}
            </a>
            <span v-else @click="headMenuClickFn(i)">{{ i.title }}</span>
          </div>
        </div>
      </div>
      <div class="header-right-ewm">
        <div class="wechat-service ewm">
          <img src="https://zhengyoulinart.obs.cn-east-3.myhuaweicloud.com/2023/01/04/35fc5c8e0122470d8a06ba812ce7532f.jpg" />
        </div>
        <div class="des">
          <div>
            <span>关注服务号</span>
          </div>
          <div>
            <span>微信扫码关注</span>
          </div>
          <div>
            <span>郑幼林雕刻艺术馆</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { get } from "../../utils/request";
export default {
  inject: ["reload"],
  data() {
    return {
      searchInput: "",
      // 所有一级栏目
      category: [],
      currentIndex: "",
      headerMenus: [],
    };
  },
  created() {
    this.getHeaderMenusFn();
    // this.getSecondCategory();
  },
  computed: {},
  methods: {
    async getHeaderMenusFn() {
      const { data: res } = await get("/public/topMenus");
      if (res.code == 200)
        this.headerMenus = res.data;
    },
    headMenuClickFn(menu) {
      if (!menu.openUrl) return Message({ message: '敬请期待，功能开发中', type: 'success', duration: 5 * 1000 });
      this.$router.push({
        path: menu.openUrl
      });
    }
  },
};
</script>
<style lang='less' scoped>
.header {
  height: 100px;
  background-color: #fff;
  border-bottom: solid 1px rgb(11, 11, 11);
  padding: 0 30px;

  display: flex;

  .header-left {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 410px;
    }
  }

  .header-right {
    width: calc(100% - 470px);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .header-right-menus {
      margin-left: 20px;
      display: flex;
      flex-direction: column;

      .header-right-menus-sign {
        img {
          width: 316px;
        }
      }

      .menus {
        display: flex;
        align-items: center;

        .menus-item {
          color: #333;
          font-size: 15px;
          font-weight: bold;
          margin-right: 20px;
        }

        .menus-item:hover {
          cursor: pointer;
        }
      }

    }

    .header-right-ewm {
      display: flex;

      .ewm {
        margin-right: 10px;

        img {
          width: 80px;
        }
      }

      .des {
        padding-top: 26px;
        font-size: 10px;
        color: #333;
        font-weight: bold;
      }
    }

  }

}
</style>